<template>
  <div class="main">
    <div class="layout">
      <div class="infos">
        <avatar class="cover" :url="philosopher.photoUrl" :is-self="false"/>
        <div class="txts">
          <p class="name">{{philosopher.penName}}</p>
          <p>{{philosopher.introduction}}</p>
        </div>
      </div>
      <div class="list">
        <div class="hd">{{$t('Daren works')}}</div>
        <div class="bd cl">
          <travels-item v-for="item of list" :datas="item" :key="item.travelsId"/>
        </div>
        <el-pagination class="pages"
                       background
                       layout="prev, pager, next"
                       :page-count="totalPages"
                       @current-change="changePage"
                       hide-on-single-page>
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import TravelsItem from './components/TravelsItem';
  import {getPhilosopheDetail} from '../../api/travels';
  import Avatar from '../../components/Avatar';

  export default {
    name: 'TravelerDetail',
    data() {
      return {
        philosopherId: '',
        philosopher: {},
        list: [],
        totalPages: 1
      };
    },
    components: {Avatar, TravelsItem},
    created() {
      this.philosopherId = this.$route.params.id;
      this.getDetail();
    },
    methods: {
      // 获取游记模块达人详情
      getDetail(page = 1) {
        const data = {page, perPage: 12};
        getPhilosopheDetail(this.philosopherId, data).then(res => {
          const {value} = res;
          const philosopherTravels = value.philosopherTravels || {};
          this.philosopher = value.philosopher || {};
          this.list = philosopherTravels.list || [];
          this.totalPages = philosopherTravels.totalPages;
        });
      },
      changePage(page) {
        this.getDetail(page);
      }
    }
  };
</script>
<style scoped lang="less">
  .main{margin-bottom:-30px;padding-bottom:30px;padding-top:1px;background-color:#f4f4f4;}
  .infos{
    margin-top:30px;padding:30px 60px;overflow:hidden;background-color:#fff;border-radius:4px;
    .cover{float:left;width:120px;height:120px;border:4px solid rgba(254, 112, 97, 0.4);}
    .txts{margin-left:140px;}
    .name{margin-bottom:8px;font-size:24px;}
  }
  .list{
    .hd{
      position:relative;margin:30px 0;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
  }
</style>
